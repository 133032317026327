import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from "@material-ui/core/TableCell";
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import PublicIcon from '@material-ui/icons/Public';
import axios from 'axios';
import * as _ from 'lodash';
import moment from 'moment';
import QRCode from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './App.css';
import apiConfig from './config/api';
import constants from "./constants";
import animationData from './lotties/data.json';

const titleColor = "#000000";
const subtitleColor = "#4C4C4C";
const detailColor = "#565656";
const numColor = "#ED631F";
const merchantDetailColor = "#999999";
var closed = true;

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: 'none',
    fontFamily: 'PingFangHK-Semibold',
    "& .MuiPaper-root": {
      borderRadius: "8px",
    }
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    fontFamily: 'PingFangHK-Semibold',
  },
  subtitle: {
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: 'PingFangHK-Semibold',
    color: subtitleColor,
  },
  font16: {
    fontSize: 16,
  },
  font13: {
    fontSize: 13,
  },
  toolbar: {
    flexGrow: 1,
    fontSize: 16,
    fontWeight: 'bold',
    color: '#FFFFFF',
    fontFamily: 'PingFangHK-Semibold',
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    fontSize: "36px",
    color: detailColor,
    backgroundColor: "#FFFFFF",
    fontFamily: 'PingFangHK-Semibold',
  },
  tablecell: {
    display: 'flex',
    align: 'center',
    fontFamily: 'PingFangHK-Semibold',
  },
  backButton: {
    color: '#FFFFFF',
    size: 'small',
    marginLeft: theme.spacing(0),
  },
  leftCell: {
    paddingRight: 0,
    width: theme.spacing(10),
    paddingBottom: 0,
    paddingTop: 0,
  },
  noPaddingBottomNTop: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  lastCell: {
    paddingTop: 5,
  },
  noPaddingBottom: {
    paddingBottom: 0,
  },
  bannerText: {
    fontSize: 20,
    color: '#000000',
    fontFamily: 'PingFangHK-Semibold',
  },
  copyableText: {
    color: '#E89845',
    cursor: 'pointer',
    '&:hover': {
      color: '#cc7a32',
    },
    '&:active': {
      color: '#bf6c1e',
    }
  }
}));
const TableCell = withStyles({
  root: {
    borderBottom: 'none',
    maxWidth: '425px'
  },
})(MuiTableCell);

const defaultProps = {
  bgcolor: 'background.paper',
  m: 1,
  border: 1,
};

const StatusProps = {
  m: 1,
  border: 0,
  style: {
    height: '8px',
    margin: '4px'
  },
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

var lang = "zh-HK";

function languageSetting() {
  if (lang === 'zh-HK') {
    lang = "en-US";
  } else if (lang === "en-US") {
    lang = "zh-HK";
  }
  return lang;
}

const formOptionList = (options) => {
  let optionList = "";
  options.forEach(function (option) {
    if (optionList !== "") {
      optionList += ", ";
    }
    if (option.quantity > 1) {
      optionList += option.name + "(" + option.quantity + ")";
    } else {
      optionList += option.name;
    }
  })
  return optionList
}

const roundMoment = (date, duration, method) => {
  return moment(Math[method]((+date) / (+duration)) * (+duration));
}

function App() {
  const classes = useStyles();
  const [orderDetail, setDetail] = useState({});
  const [merchantDetail, setMerchant] = useState({});
  const { t, i18n } = useTranslation();
  const [timeNow, setTime] = useState(moment());
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    closed = false;
  };
  const id = window.location.pathname.replace('/', '');
  const params = (new URL(document.location)).searchParams;
  const isAdmin = params.get('doadmin');
  const debug = isAdmin ? '?debug=true' : '';
  const url = apiConfig.baseUrl + '/g/order/' + id + debug;

  useEffect(() => {
    fetchData();
    if (orderDetail.merchantId) {
      fetchMerchantData();
    }
    setInterval(() => {
      fetchData();
      setTime(moment());
    }, 10000);
  }, [orderDetail.merchantId]);

  const fetchData = () => {
    axios.get(url, { headers: { 'x-api-version': '2.0.0' } })
      .then(response => {
        const orderData = response.data;
        const isCancelled = orderData.status === "cancelled";
        if (!isAdmin) {
          setOpen(isCancelled);
        }
        orderData.displayModifiers = (orderData.modifiers || []).filter(m => {
          if (m.id === "SURCHARGE" && m.calculatedAmount === 0) {
            return false
          }
          if (m.id === "SHIPPING_DISCOUNT") {
            return false
          }
          return true
        })
        setDetail(orderData);
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleCopyRefundForm = () => {
    const paymentMethodMap = {
      payme: 'Payme',
      wechatpay: 'WechatPay',
      alipay: 'Alipay',
      visa: 'Visa',
      master: 'Master',
      applepay: 'Applepay',
    }
    const payment = orderDetail.payments.filter(p => p.status === 'paid')[0]
    const text = [
      orderDetail.serial,
      merchantDetail.name,
      moment(orderDetail.paidAt).format('DD/MM/YYYY'),
      paymentMethodMap[payment.paymentMethod] || payment.paymentMethod,
      orderDetail.roundedTotal,
    ].join('\t')
    navigator.clipboard.writeText(text)
  }

  const handleCopyOrder = () => {
    const startLocation = orderDetail.shipping.lalamove.startLat + ',' + orderDetail.shipping.lalamove.startLng
    const endLocation = orderDetail.shipping.lalamove.endLat + ',' + orderDetail.shipping.lalamove.endLng
    const link = `https://www.google.com/maps/dir/?api=1&destination=${endLocation}&travelmode=driving&waypoints=${startLocation}`
    const time = moment(orderDetail.shipping.lalamove.scheduleAt).format('HH:mm')
    const text = `${time}\n#${orderDetail.serial}\n\n${merchantDetail.name} | ${merchantDetail.contact.replace(' ', '')} | ${merchantDetail.address}\n${orderDetail.name} | ${orderDetail.phone} | ${orderDetail.address}\n樓層: ${orderDetail.shipping.lalamove.endFloorRoom}\n${link}`
    navigator.clipboard.writeText(text)
  }
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
  }


  const fetchMerchantData = () => {
    axios.get(apiConfig.baseUrl + "/g/merchant/" + orderDetail.merchantId)
      .then(response => {
        const merchantData = response.data;
        setMerchant(merchantData);
      })
      .catch(error => {
        console.log(error)
      })
  }

  const modifierName = (customName, modifierType) => {
    if (customName) {
      return customName
    } else {
      if (modifierType === 'item') return (t('itemDiscount'))
      if (modifierType === 'order') return (t('discountTotal'))
    }
  }

  if (merchantDetail.id) {
    var itemList = [];
    var itemsList = [];
    var set = -1;
    var qrcodeLink = "completeTakeawayOrder:" + orderDetail.id + ":" + orderDetail.code;
    var minDiff = moment(timeNow).isBefore(orderDetail.pickupAt) ? parseInt(moment(timeNow).diff(orderDetail.pickupAt, 'minutes').toString().replace('-', '')) : 0;
    var hrDiff = parseInt(moment(timeNow).diff(orderDetail.pickupAt, 'hours').toString().replace('-', ''));
    const mapurl = "https://www.google.com.hk/maps/search/" + merchantDetail.address;
    const telherf = "tel:" + merchantDetail.contact;

    let shippingFee = 0
    let originalShippingFee = 0
    const shippingDiscounts = _.get(orderDetail.shipping, "discounts", [])
    const discounts = shippingDiscounts ? shippingDiscounts.sort((a, b) => a.minOrderAmount > b.minOrderAmount ? -1 : 1) : []
    const discount = discounts.find((discount) => orderDetail.originalTotal >= discount.minOrderAmount)
    const shippingDiscount = discount ? _.get(discount, "amount", 0) : 0

    if (orderDetail.apiVersion === "1.0.0") {
      shippingFee = orderDetail.shippingFee// _.get(orderDetail.shipping, "customerAmount", 0)
      originalShippingFee = orderDetail.originalShippingFee
    } else if (orderDetail.apiVersion === "2.0.0") {
      shippingFee = _.get(orderDetail.shipping, "customerAmount", 0)
      if (shippingDiscount !== 0) {
        shippingFee -= Math.max(shippingDiscount, 0)
      }
      originalShippingFee = orderDetail.originalShippingFee
    }
    shippingFee = Math.max(shippingFee, 0)

    function deliveryTime() {
      const startTime = moment(orderDetail.pickupAt).minute(Math.ceil(moment(orderDetail.pickupAt).minute() / 5) * 5)
      const endTime = startTime.clone().add(15, 'm')

      let text = t('deliveredSoon')
      if (!(moment().isSame(startTime, 'day'))) { // 不是同一天
        text = moment(orderDetail.pickupAt).format('YYYY-MM-DD')
      } else if (moment().isBefore(endTime)) { // time range
        text = `${startTime.format('HH:mm')} - ${endTime.format('HH:mm')}`
      }
      return <Typography style={{ color: titleColor, fontSize: 30, fontFamily: 'PingFangHK-Semibold' }} gutterBottom>
        {text}
      </Typography>
    }

    function isSet(row) {
      if (orderDetail.apiVersion === "1.0.0") {
        if (row.setMenuIndex !== null && row.setMenuIndex !== undefined) {
          if (row.setMenuIndex === set) {
            return true;
          } else {
            set = row.setMenuIndex;
            return false;
          }
        }
      }
      return row.isSet
    }

    if (orderDetail.apiVersion === "1.0.0") {
      if (itemList.length === 0) {
        for (var i = 0; i < orderDetail.batches.length; i++) {
          const isBatchStatusValid = ['submitted', 'confirmed'].includes(orderDetail.batches[i].status)
          if (!isBatchStatusValid) {
            continue
          }
          orderDetail.batches[i].items = orderDetail.batches[i].items.map(function (item) {
            if (item.setMenuIndex !== null) {
              item.setMenuIndex += (99 - i);
            }
            return item;
          }).filter(function (item) {
            return item.cancelled !== true;
          })
          itemList = itemList.concat(orderDetail.batches[i].items);
        }
      }

      itemList = itemList.concat(orderDetail.customItems);
      itemList.forEach(function (item) {
        if (item.quantity > 1) {
          for (var i = 0; i < item.quantity; i++) {
            const optionList = formOptionList(_.get(item.options, i, []));
            const total = _.get(item.totals, i) || item.total
            const num = itemsList.findIndex(function (items) {
              return items.option === optionList && items.name === item.name && items.total === total;
            });
            if (num !== -1 && item.setMenuIndex === itemsList[num].setMenuIndex) {
              itemsList[num].quantity += 1;
            } else {
              itemsList.push({
                id: item.id + i,
                name: item.name,
                option: optionList,
                quantity: 1,
                total,
                setMenuIndex: item.setMenuIndex,
                remarks: item.remarks,
              });
            }
          }
        } else {
          const optionList = formOptionList(_.get(item.options, 0, []));
          const total = item.total || _.get(item.totals, 0)
          // FIXME: optionList is not defined
          itemsList.push({
            id: item.id,
            name: item.name,
            option: optionList,
            quantity: 1,
            total,
            setMenuIndex: item.setMenuIndex,
            remarks: item.remarks,
          });
        }
      })
    } else if (orderDetail.apiVersion === "2.0.0") {
      if (itemList.length === 0) {
        itemList = itemList.concat(orderDetail.summary)
      }

      itemList.forEach((item) => {
        const optionList = formOptionList(item.options)
        itemsList.push({
          id: item.id,
          name: item.name,
          option: optionList,
          quantity: item.quantity,
          total: item.unitPrice,
          isSet: false,
          remarks: item.remarks
        })
        if (item.items.length > 0) {
          item.items.forEach((setItem) => {
            const setItemOptionList = formOptionList(setItem.options)
            itemsList.push({
              id: setItem.id,
              name: setItem.name,
              option: setItemOptionList,
              quantity: setItem.quantity,
              total: setItem.unitPrice,
              isSet: true,
              remarks: setItem.remarks
            })
          })
        }
      })
    }

    var takeawayStatus = 0;
    var storeDeliveryStatus = 0;

    if (orderDetail.takeawayStatus === "pending") {
      takeawayStatus = 1;
      storeDeliveryStatus = 1;
    } else if (orderDetail.takeawayStatus === "confirmed") {
      takeawayStatus = 2;
      storeDeliveryStatus = 2;
    } else if (orderDetail.takeawayStatus === "ready") {
      takeawayStatus = 3;
    } else if (orderDetail.takeawayStatus === "completed") {
      takeawayStatus = 4;
      storeDeliveryStatus = 3;
      if (orderDetail.shipping.lalamove) {
        if (orderDetail.shipping.lalamove.status === "PICKED_UP") {
          storeDeliveryStatus = 4;
        } else if (orderDetail.shipping.lalamove.status === "COMPLETED") {
          storeDeliveryStatus = 5;
        }
      }
    } else if (orderDetail.takeawayStatus === "delivered") {
      storeDeliveryStatus = 5;
    }

    const isNwd = merchantDetail.name.includes('新世界大廈')

    const isReferOrder = id !== orderDetail.id

    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} >
        <AppBar position="static" style={{ background: "linear-gradient(to right, #ffa344, #febb51)", boxShadow: "none" }}>
          <Toolbar>
            <Typography className={classes.toolbar}>{t('order')}</Typography>
            <Tooltip title={i18n.language === "zh-HK" ? "ENG" : "中文"}>
              <IconButton style={{ color: "#FFFFFF" }} aria-label="upload picture" component="span" onClick={() => changeLanguage(languageSetting())}>
                <PublicIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <div className="flex-container" className={classes.root}>
          <Dialog open={open && closed} onClose={handleClose} aria-describedby="alert-dialog-description">
            <div className="popup">
              <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" >
                <p style={{ fontSize: 18, marginBlockEnd: 0, lineHeight: 1 }}>{t('CancelNotification1')}</p>
                <p style={{ overflowWrap: "break-word", display: "inline-block", fontSize: 16, color: detailColor, marginBlockEnd: 0, lineHeight: 1 }}>{t('CancelNotification2')}</p>
                <p style={{ fontSize: 16, color: "#1A84DB", lineHeight: 0 }}><a id="phone" href="https://s.dimorder.com/cs">68262032</a></p>
              </Box>
              <Box style={{ width: '5rem', height: '20px' }} />
              <Button onClick={handleClose} style={{ background: "linear-gradient(to right, #ffa344, #febb51)", fontSize: 20, fontFamily: 'PingFangHK-Semibold' }}>{t("close")}</Button>
            </div>
          </Dialog>
          <Card className="logoBanner" variant="outlined" onClick={(e) => {
            e.preventDefault();
            window.open('https://app.dimorder.com/')
          }}>
            <Box className="logoBox">
              <img style={{ width: '320px', maxWidth: '100%' }} src={'https://img.dimorder.com/414x/public/logo/receipt_logo.png'} alt="Banner" />
            </Box>
            <Box className="logoText">
              <Typography className={classes.bannerText}>{t('newOrder')}</Typography>
            </Box>
          </Card>
          <Card className="orderStatus" variant="outlined">
            {
              orderDetail.deliveryType === "takeaway" &&
              <div>
                <Box style={{ width: '5rem', height: '8px' }} />
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.noPaddingBottom}>{
                        orderDetail.status === "cancelled" &&
                        <Typography className={classes.title} gutterBottom>{t('cancelled')}</Typography>
                      }
                        {
                          takeawayStatus === 4
                            ? <Typography className={classes.title} gutterBottom>{t('takeawayCompleted')}</Typography>
                            : <Typography className={classes.title} gutterBottom>{t('takeaway')}</Typography>
                        }
                      </TableCell>
                      <TableCell className={classes.noPaddingBottom} align="right">
                        <Typography className={classes.subtitle} gutterBottom align="right">
                          {t('orderSerial')} <span style={{ color: numColor }}>{orderDetail.orderSerial}</span>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {
                  takeawayStatus === 1 &&
                  <Box display="flex" justifyContent="center" width="100%">
                    <Box width={1 / 5} {...StatusProps} borderRadius={16} bgcolor="#FF9843"></Box>
                    <Box width={1 / 5} {...StatusProps} borderRadius={16} bgcolor="#EAEAEA" className="statusBar">
                      <div className="ui active indicating progress"><div className="bar"></div></div>
                    </Box>
                    <Box width={1 / 5} {...StatusProps} borderRadius={16} bgcolor="#EAEAEA"></Box>
                    <Box width={1 / 5} {...StatusProps} borderRadius={16} bgcolor="#EAEAEA"></Box>
                  </Box>
                }
                {
                  takeawayStatus === 2 &&
                  <Box display="flex" justifyContent="center" width="100%">
                    <Box width={1 / 5} {...StatusProps} borderRadius={16} bgcolor="#FF9843"></Box>
                    <Box width={1 / 5} {...StatusProps} borderRadius={16} bgcolor="#FF9843"></Box>
                    <Box width={1 / 5} {...StatusProps} borderRadius={16} bgcolor="#EAEAEA" className="statusBar">
                      <div className="ui active indicating progress"><div className="bar"></div></div>
                    </Box>
                    <Box width={1 / 5} {...StatusProps} borderRadius={16} bgcolor="#EAEAEA"></Box>
                  </Box>
                }
                {
                  takeawayStatus === 3 &&
                  <Box display="flex" justifyContent="center" width="100%">
                    <Box width={1 / 5} {...StatusProps} borderRadius={16} bgcolor="#FF9843"></Box>
                    <Box width={1 / 5} {...StatusProps} borderRadius={16} bgcolor="#FF9843"></Box>
                    <Box width={1 / 5} {...StatusProps} borderRadius={16} bgcolor="#FF9843"></Box>
                    <Box width={1 / 5} {...StatusProps} borderRadius={16} bgcolor="#EAEAEA" className="statusBar">
                      <div className="ui active indicating progress"><div className="bar"></div></div>
                    </Box>
                  </Box>
                }
                {
                  takeawayStatus === 4 &&
                  <Box display="flex" justifyContent="center" width="100%">
                    <Box width={1 / 5} {...StatusProps} borderRadius={16} bgcolor="#FF9843"></Box>
                    <Box width={1 / 5} {...StatusProps} borderRadius={16} bgcolor="#FF9843"></Box>
                    <Box width={1 / 5} {...StatusProps} borderRadius={16} bgcolor="#FF9843"></Box>
                    <Box width={1 / 5} {...StatusProps} borderRadius={16} bgcolor="#FF9843"></Box>
                  </Box>
                }
                <Box style={{ width: '5rem', height: '10px' }} />
                {
                  takeawayStatus === 1 &&
                  <Box>
                    <Box ml={2} mt={1}>
                      <Typography className={classes.font16} gutterBottom><span style={{ color: detailColor }}>{t('takeawayStep1-1')}</span></Typography>
                      <Typography className={classes.font16} gutterBottom><span style={{ color: detailColor }}>{t('takeawayStep1-2')}</span></Typography>
                    </Box>
                    {/* <Lottie options={defaultOptions} /> */}
                  </Box>
                }
                {
                  takeawayStatus === 2 &&
                  <Box>
                    <Box ml={2} mt={1}>
                      <Typography className={classes.font16} gutterBottom><span style={{ color: detailColor }}>{t('takeawayStep2')}</span></Typography>
                    </Box>
                  </Box>
                }
                {
                  takeawayStatus === 3 &&
                  <Box>
                    <Box ml={2} mt={1}>
                      <Typography className={classes.font16} gutterBottom><span style={{ color: detailColor }}>{t('takeawayStep3')}</span></Typography>
                    </Box>
                    {
                      orderDetail.code && (
                        <>
                          <Box display="flex" justifyContent="center" width="100%">
                            <Box borderColor="grey.500" {...defaultProps} justifyContent="center">
                              <Avatar variant="square" className={classes.avatar}>{String(orderDetail.code).charAt(0)}</Avatar>
                            </Box>
                            <Box borderColor="grey.500" {...defaultProps} justifyContent="center">
                              <Avatar variant="square" className={classes.avatar}>{String(orderDetail.code).charAt(1)}</Avatar>
                            </Box>
                            <Box borderColor="grey.500" {...defaultProps} justifyContent="center">
                              <Avatar variant="square" className={classes.avatar}>{String(orderDetail.code).charAt(2)}</Avatar>
                            </Box>
                            <Box borderColor="grey.500" {...defaultProps} justifyContent="center">
                              <Avatar variant="square" className={classes.avatar}>{String(orderDetail.code).charAt(3)}</Avatar>
                            </Box>
                          </Box>
                          <Box style={{ width: '5rem', height: '20px' }} />
                          <Box display="flex" justifyContent="center">
                            <QRCode value={qrcodeLink} size={150} />
                          </Box>
                        </>
                      )
                    }
                  </Box>
                }
                {
                  takeawayStatus === 4 &&
                  <Box>
                    <Box ml={2} mt={1}>
                      <Typography className={classes.font16} gutterBottom><span style={{ color: detailColor }}>{t('takeawayStep4')}</span></Typography>
                    </Box>
                  </Box>
                }
              </div>
            }
            {
              !isNwd &&
              orderDetail.deliveryType === "storeDelivery" &&
              <div>
                <Box style={{ width: '350px', height: '8px' }} />
                {
                  orderDetail.status === "cancelled"
                    ? <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" width="100%" mt={2} className="titleBox">
                      <Typography className={classes.font16} gutterBottom><span style={{ color: titleColor, fontSize: 36, lineHeight: 0 }}>{t('cancelled')} </span></Typography>
                    </Box>
                    : <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" width="100%" mt={2} className="titleBox" style={{ lineHeight: 0 }}>
                      {
                        storeDeliveryStatus === 5
                          ? <Typography className={classes.font16} gutterBottom><span style={{ color: titleColor, fontSize: 36, lineHeight: 0 }}>{t('orderCompleted')}</span></Typography>
                          :
                          deliveryTime()
                      }
                      {
                        storeDeliveryStatus !== 5 &&
                        <Typography className={classes.font16} gutterBottom><span style={{ color: subtitleColor, fontSize: 12, lineHeight: 0 }}>{t('deliveryTime')}</span></Typography>
                      }
                    </Box>
                }
                {
                  storeDeliveryStatus === 1 &&
                  <Box display="flex" justifyContent="center" width="100%">
                    <Box width={1 / 6} {...StatusProps} borderRadius={16} bgcolor="#FF9843"></Box>
                    <Box width={1 / 6} {...StatusProps} borderRadius={16} bgcolor="#EAEAEA" className="statusBar">
                      <div className="ui active indicating progress"><div className="bar"></div></div>
                    </Box>
                    <Box width={1 / 6} {...StatusProps} borderRadius={16} bgcolor="#EAEAEA"></Box>
                    <Box width={1 / 6} {...StatusProps} borderRadius={16} bgcolor="#EAEAEA"></Box>
                    <Box width={1 / 6} {...StatusProps} borderRadius={16} bgcolor="#EAEAEA"></Box>
                  </Box>
                }
                {
                  storeDeliveryStatus === 2 &&
                  <Box display="flex" justifyContent="center" width="100%">
                    <Box width={1 / 6} {...StatusProps} borderRadius={16} bgcolor="#FF9843"></Box>
                    <Box width={1 / 6} {...StatusProps} borderRadius={16} bgcolor="#FF9843"></Box>
                    <Box width={1 / 6} {...StatusProps} borderRadius={16} bgcolor="#EAEAEA" className="statusBar">
                      <div className="ui active indicating progress"><div className="bar"></div></div>
                    </Box>
                    <Box width={1 / 6} {...StatusProps} borderRadius={16} bgcolor="#EAEAEA"></Box>
                    <Box width={1 / 6} {...StatusProps} borderRadius={16} bgcolor="#EAEAEA"></Box>
                  </Box>
                }
                {
                  storeDeliveryStatus === 3 &&
                  <Box display="flex" justifyContent="center" width="100%">
                    <Box width={1 / 6} {...StatusProps} borderRadius={16} bgcolor="#FF9843"></Box>
                    <Box width={1 / 6} {...StatusProps} borderRadius={16} bgcolor="#FF9843"></Box>
                    <Box width={1 / 6} {...StatusProps} borderRadius={16} bgcolor="#FF9843"></Box>
                    <Box width={1 / 6} {...StatusProps} borderRadius={16} bgcolor="#EAEAEA" className="statusBar">
                      <div className="ui active indicating progress"><div className="bar"></div></div>
                    </Box>
                    <Box width={1 / 6} {...StatusProps} borderRadius={16} bgcolor="#EAEAEA"></Box>
                  </Box>
                }
                {
                  storeDeliveryStatus === 4 &&
                  <Box display="flex" justifyContent="center" width="100%">
                    <Box width={1 / 6} {...StatusProps} borderRadius={16} bgcolor="#FF9843"></Box>
                    <Box width={1 / 6} {...StatusProps} borderRadius={16} bgcolor="#FF9843"></Box>
                    <Box width={1 / 6} {...StatusProps} borderRadius={16} bgcolor="#FF9843"></Box>
                    <Box width={1 / 6} {...StatusProps} borderRadius={16} bgcolor="#FF9843"></Box>
                    <Box width={1 / 6} {...StatusProps} borderRadius={16} bgcolor="#EAEAEA" className="statusBar">
                      <div className="ui active indicating progress"><div className="bar"></div></div>
                    </Box>
                  </Box>
                }
                {
                  storeDeliveryStatus === 5 &&
                  <Box display="flex" justifyContent="center" width="100%">
                    <Box width={1 / 6} {...StatusProps} borderRadius={16} bgcolor="#FF9843"></Box>
                    <Box width={1 / 6} {...StatusProps} borderRadius={16} bgcolor="#FF9843"></Box>
                    <Box width={1 / 6} {...StatusProps} borderRadius={16} bgcolor="#FF9843"></Box>
                    <Box width={1 / 6} {...StatusProps} borderRadius={16} bgcolor="#FF9843"></Box>
                    <Box width={1 / 6} {...StatusProps} borderRadius={16} bgcolor="#FF9843"></Box>
                  </Box>
                }
                <Box style={{ width: '5rem', height: '20px' }} />
                {
                  storeDeliveryStatus === 1 &&
                  <Box>
                    <Box ml={2} mt={1}>
                      <Typography className={classes.font16} gutterBottom><span style={{ color: detailColor }}>{t('storeDeliveryStep1-1')}</span></Typography>
                      <Typography className={classes.font16} gutterBottom><span style={{ color: detailColor }}>{t('storeDeliveryStep1-2')}</span></Typography>
                    </Box>
                  </Box>
                }
                {
                  storeDeliveryStatus === 2 &&
                  <Box>
                    <Box ml={2} mt={1}>
                      <Typography className={classes.font16} gutterBottom><span style={{ color: detailColor }}>{t('storeDeliveryStep2')}</span></Typography>
                    </Box>
                  </Box>
                }
                {
                  storeDeliveryStatus === 3 &&
                  <Box>
                    <Box ml={2} mt={1}>
                      <Typography className={classes.font16} gutterBottom><span style={{ color: detailColor }}>{t('storeDeliveryStep3')}</span></Typography>
                    </Box>
                  </Box>
                }
                {
                  storeDeliveryStatus === 4 &&
                  <Box>
                    <Box ml={2} mt={1}>
                      <Typography className={classes.font16} gutterBottom><span style={{ color: detailColor }}>{t('storeDeliveryStep4')}</span></Typography>
                    </Box>
                  </Box>
                }
                {
                  storeDeliveryStatus === 5 &&
                  <Box marginRight="16px">
                    <Box ml={2} mt={1}>
                      <Typography className={classes.font16} gutterBottom><span style={{ color: detailColor }}>{t('storeDeliveryStep5')}</span></Typography>
                    </Box>
                  </Box>
                }
              </div>
            }
            <br />
            <Table style={{ backgroundColor: "#F3F3F3" }}>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2} className={classes.noPaddingBottomNTop}>
                    <Box style={{ width: '5rem', height: '10px' }} />
                    <Typography className={classes.font16} gutterBottom><span style={{ color: titleColor }}><b>{merchantDetail.name}</b></span></Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.leftCell} style={{ verticalAlign: 'top' }}>
                    {
                      orderDetail.deliveryType === "storeDelivery"
                        ? <Typography className={classes.font13} gutterBottom><span style={{ color: subtitleColor }}><b>{t('customerAddress')}</b></span></Typography>
                        : <Typography className={classes.font13} gutterBottom><span style={{ color: subtitleColor }}><b>{t('address')}</b></span></Typography>
                    }
                  </TableCell>
                  <TableCell className={classes.noPaddingBottomNTop}>
                    {
                      isNwd ?
                        <Typography className={classes.font13} gutterBottom align="right"><span style={{ color: merchantDetailColor }}>315室</span></Typography>
                        : (
                          orderDetail.deliveryType === "storeDelivery"
                            ? <Typography className={classes.font13} gutterBottom align="right"><span style={{ color: merchantDetailColor }}>{orderDetail.address}</span></Typography>
                            : <Typography className={classes.font13} gutterBottom align="right"><span style={{ color: merchantDetailColor }}><a href={mapurl}>{merchantDetail.address}</a></span></Typography>
                        )
                    }
                  </TableCell>
                </TableRow>
                {
                  // FIXME: only for nwd
                  // !isNwd &&
                  // <TableRow>
                  //   <TableCell className={classes.leftCell}>
                  //     <Typography className={classes.font13} gutterBottom><span style={{ color: subtitleColor }}><b>{t('contact')}</b></span></Typography>
                  //   </TableCell>
                  //   <TableCell className={classes.noPaddingBottomNTop}>
                  //     <Typography className={classes.font13} gutterBottom align="right"><span style={{ color: merchantDetailColor }}><a id="phone" href={telherf}>{merchantDetail.contact}</a></span></Typography>
                  //   </TableCell>
                  // </TableRow>
                }
              </TableBody>
            </Table>
          </Card>
          {
            isAdmin && (
              <Card className="adminCard" variant="outlined">
                <code>訂單類型</code>
                <code>{t(orderDetail.deliveryType)}</code>
                <code> 餐廳地址： </code>
                <code>{merchantDetail.address}</code>
                <code> 餐廳電話： </code>
                <code>{merchantDetail.contact}</code>

                <code> status:</code>
                <code> {orderDetail.status}</code>
                <code> takeawayStatus:</code>
                <code> {orderDetail.takeawayStatus}</code>

                <code> cancelIdentifier:</code>
                <code> {orderDetail.cancelIdentifier}</code>
                <code> cancelReason:</code>
                <code> {orderDetail.cancelReason}</code>

                <code> payments:</code>
                <code>
                  <ol>
                    {
                      _.get(orderDetail, 'payments', []).map((p, i) => {
                        return <li key={i}>
                          <ul>
                            <li>
                              {p.paymentMethod} 
                              {
                                (p.gateway && p.gateway === 'fiserv') ?
                                  <span className={classes.copyableText} onClick={() => window.open(`https://www4.ipg-online.com/vt/redirect/startIPGVT.jsp?orderId=${p.ref}&startURL=showOrderDetails&storeOfAdmin=null`)}>
                                    {`(${p.gateway})`}
                                  </span>
                                  : (
                                    <span>{p.gateway && `(${p.gateway})`}</span>
                                  )
                              }
                            </li>
                            <li> payment status:
                              {p.status}
                            </li>
                            <li> payment amount:
                              {p.paidAmount}
                            </li>
                            {
                              (p.gateway === 'qfpay' || p.gateway === 'fiserv') &&
                              <li>
                                ref: <span className={classes.copyableText} onClick={() => { handleCopy(p.ref) }}>{p.ref}</span>
                              </li>
                            }
                            <li>
                              {moment(p.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                            </li>
                          </ul>
                        </li>
                      })
                    }
                  </ol>
                </code>



                <code> 訂單ID： </code>
                <code>
                  <code className={classes.copyableText} onClick={() => { handleCopy(orderDetail.id) }}>{orderDetail.id}</code>
                  {
                    isReferOrder && <h2>Refer Order</h2>
                  }
                  <h1 className={classes.copyableText} onClick={() => { handleCopy(orderDetail.serial) }}>{orderDetail.serial}</h1>
                </code>
                <Button onClick={() => {
                  window.open(`${apiConfig.mpUrl}/${orderDetail.id}`)
                }} style={{ color: 'white', background: "linear-gradient(to right, #00c1ba, #00c1ba)", margin: 10 }}>
                  Open in MP
                </Button>
                <Button onClick={() => {
                  const query = encodeURIComponent([
                    `resource.type="global"`,
                    `"${orderDetail.id}"`,
                  ].join('\n'))
                  const startTime = moment(orderDetail.createdAt).subtract(1, 'hour').format('YYYY-MM-DDTHH:mm:ssZ')
                  const endTime = moment(orderDetail.createdAt).add(3, 'hour').format('YYYY-MM-DDTHH:mm:ssZ')
                  const timeRange = encodeURIComponent([
                    startTime,
                    endTime,
                  ].join('/'))
                  window.open(`https://console.cloud.google.com/logs/query;query=${query};timeRange=${timeRange}?project=dimorder`)
                }} style={{ background: "linear-gradient(to right, #f0f0f0, #f0f0f0)", margin: 10 }}>
                  Log URL
                </Button>

                <code> From Version： </code>
                <code>{orderDetail.from} {orderDetail.apiVersion}</code>
                <code> 客人姓名：</code>
                <code>{orderDetail.name}</code>
                <code> 客人地址：</code>
                <code>{orderDetail.address}</code>
                <code> 客人電話： </code>
                <code>{orderDetail.phone}</code>
                {
                  [
                    constants.ORDER_DELIVERY_TYPE_STOREDELIVERY,
                    constants.ORDER_DELIVERY_TYPE_TAKEAWAY,
                  ].includes(orderDetail.deliveryType) &&
                  _.get(orderDetail, 'paidAt') && (
                    <>
                      <code> 提交時間： </code>
                      <code>{moment(orderDetail.paidAt).format('YYYY-MM-DD HH:mm:ss')}</code>
                    </>
                  )
                }
                {
                  [
                    constants.ORDER_DELIVERY_TYPE_STOREDELIVERY,
                    constants.ORDER_DELIVERY_TYPE_TAKEAWAY,
                  ].includes(orderDetail.deliveryType) &&
                  _.get(orderDetail, 'batches[0].confirmedAt') && (
                    <>
                      <code> 餐廳確認時間： </code>
                      {
                        orderDetail.batches.map((b, i) => (
                          <code key={i}>
                            batch#{i}: {moment(b.confirmedAt).format('YYYY-MM-DD HH:mm:ss')}
                          </code>
                        ))
                      }
                    </>
                  )
                }
                {
                  orderDetail.deliveryType === constants.ORDER_DELIVERY_TYPE_STOREDELIVERY && (
                    <>
                      <code> 司機取餐時間： </code>
                      <code>{moment(orderDetail.shipping.lalamove.scheduleAt).format('YYYY-MM-DD HH:mm:ss')}</code>
                    </>
                  )
                }
                <code> 客人取餐時間： </code>
                {
                  orderDetail.pickupAt &&
                  <code>{moment(orderDetail.pickupAt).format('YYYY-MM-DD HH:mm:ss')}</code>
                }
                {
                  orderDetail.deliveryType === constants.ORDER_DELIVERY_TYPE_STOREDELIVERY && (
                    <>
                      <h4>運費</h4>
                      <code>餐廳運費折扣：</code>
                      {
                        orderDetail.shipping.discounts.map(discount => {
                          if (discount.amount > 0) {
                            return (<code>滿${discount.minOrderAmount}折${discount.amount}</code>)
                          }
                          return <>無</>
                        })
                      }
                      {/* TODO: modifier shipping discount? */}
                    </>
                  )}
                <Button onClick={handleCopyOrder} style={{ background: "linear-gradient(to right, #ffa344, #febb51)", margin: 10 }}>
                  Copy
                </Button>
                <Button onClick={handleCopyRefundForm} style={{ background: "linear-gradient(to right, #7190ad, #b8c7d6)", margin: 10 }}>
                  Copy to refund form
                </Button>

              </Card>
            )
          }
          <Card className="orderItem" variant="outlined">
            <Box style={{ width: '5rem', height: '18px' }} />
            <Table>
              <TableBody>
                {itemsList.map((row, index) => {
                  const remarks = _.flattenDeep(row.remarks).join(', ')
                  return (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row" colSpan={2} border-bottom-style='none' className={classes.noPaddingBottomNTop}>
                        <Typography className={classes.font16} gutterBottom style={{ lineHeight: 1 }}>
                          {
                            isSet(row)
                              ? <span style={{ paddingLeft: 16, color: subtitleColor }}> 跟 <b>{row.name}</b></span>
                              : <span style={{ color: subtitleColor }}><b>{row.name}</b></span>
                          }
                        </Typography>
                        {!!row.option &&
                          <Typography className={classes.font13} gutterBottom style={{ lineHeight: 1 }}>
                            <span style={{ paddingLeft: isSet(row) ? 16 : 0, color: detailColor }}>{row.option}</span>
                          </Typography>
                        }
                        {
                          !!remarks &&
                          <Typography className={classes.font13} gutterBottom style={{ lineHeight: 1 }}>
                            <span style={{ paddingLeft: isSet(row) ? 16 : 0, color: detailColor }}>**{remarks}</span>
                          </Typography>
                        }
                        <Typography className={classes.font16} gutterBottom style={{ lineHeight: 1 }}>
                          <span style={{ paddingLeft: isSet(row) ? 16 : 0, color: "#ED631F" }}><b>HK${row.total}</b></span>
                        </Typography>
                        <Box style={{ width: '5rem', height: '12px' }} />
                      </TableCell >
                      <TableCell align="right" className={classes.noPaddingBottomNTop}>
                        <Typography className={classes.font16} gutterBottom><b>{row.quantity}</b></Typography>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Card>
          <Card className="priceDetail" variant="outlined">
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.noPaddingBottom}>
                    <Typography className={classes.font16} gutterBottom><span style={{ color: subtitleColor }}>{t('originalTotal')}</span></Typography>
                    {
                      (orderDetail.apiVersion === "2.0.0" && _.get(orderDetail, 'modifiers.length') > 0) &&
                      (
                        orderDetail.displayModifiers.map((modifier, index) => (
                          <Typography className={classes.font16} gutterBottom><span style={{ color: subtitleColor }} key={index}>
                            {
                              modifier.type === 'RICECOIN'
                                ? 'RICECOIN'
                                : (
                                  modifier.type === 'PROMOCODE'
                                    ? (modifier.percent ? modifier.code + `(${modifier.percent}%)` : modifier.code)
                                    : (
                                      modifier.type === 'MERCHANT'
                                        ? (modifier.percent ? modifierName(modifier.customName, 'order') + `(${modifier.percent}%)` : modifierName(modifier.customName, 'order'))
                                        : (
                                          modifier.type === 'SURCHARGE'
                                            ? (modifier.percent ? t('surcharge') + `(${modifier.percent}%)` : t('surcharge'))
                                            : (modifier.percent ? modifierName(modifier.customName, 'order') + `(${modifier.percent}%)` : modifierName(modifier.customName, 'order'))
                                        )
                                    )
                                )
                            }
                          </span></Typography>
                        ))
                      )
                    }
                    {
                      (orderDetail.apiVersion === "1.0.0" && orderDetail.surchargeTotal > 0) &&
                      <Typography className={classes.font16} gutterBottom><span style={{ color: subtitleColor }}>{
                        t('surcharge') + `(${orderDetail.surcharge.percent}%)`
                      }</span></Typography>
                    }
                    {
                      (orderDetail.apiVersion === "1.0.0" && orderDetail.discountTotal > 0) &&
                      <Typography className={classes.font16} gutterBottom><span style={{ color: subtitleColor }}>{t('discountTotal')}</span></Typography>
                    }
                    {
                      orderDetail.batches?.map((batch) => (
                        batch?.items?.map((item) => (
                          (orderDetail.apiVersion === "2.0.0" && _.get(item, 'modifiers.length') > 0 && !item.cancelled) && (
                            item?.modifiers?.map((modifier) => (
                              modifier?.map((m, index) => (
                                <Typography className={classes.font16} gutterBottom><span style={{ color: subtitleColor }} key={index}>
                                  {
                                    m.type === 'MERCHANT'
                                      //? (m.percent ? t('discountTotal') + `－${item.name}(${m.percent}%)` : t('discountTotal') + `－${item.name}`)
                                      ? ''
                                      : (
                                        modifier.type === 'SURCHARGE'
                                          ? (m.percent ? t('surcharge') + `－${item.name}(${m.percent}%)` : t('surcharge') + `－${item.name}`)
                                          : (m.percent ? modifierName(m.customName, 'item') + `－${item.name}(${m.percent}%)` : modifierName(m.customName, 'item') + `－${item.name}`)
                                      )
                                  }
                                </span></Typography>
                              ))
                            ))
                          )
                        ))
                      ))
                    }
                    {
                      orderDetail.deliveryType === "storeDelivery" &&
                      <Typography className={classes.font16} gutterBottom><span style={{ color: subtitleColor }}>{t('shippingFee')}</span></Typography>
                    }
                  </TableCell>
                  <TableCell className={classes.noPaddingBottom}>
                    <Typography className={classes.font16} gutterBottom align="right"><span style={{ color: titleColor }}>HK$ {orderDetail.originalTotal.toFixed(1)}</span></Typography>
                    {
                      (orderDetail.apiVersion === "2.0.0" && _.get(orderDetail, 'modifiers.length') > 0) &&
                      (
                        orderDetail.displayModifiers.map((modifier, index) => (
                          <Typography className={classes.font16} gutterBottom align="right"><span style={{ color: titleColor }} key={index}>HK$ {modifier.calculatedAmount.toFixed(1)}</span></Typography>
                        ))
                      )
                    }
                    {
                      (orderDetail.apiVersion === "1.0.0" && orderDetail.surchargeTotal > 0) &&
                      <Typography className={classes.font16} gutterBottom align="right"><span style={{ color: titleColor }}>HK$ {orderDetail.surchargeTotal.toFixed(1)}</span></Typography>
                    }
                    {
                      (orderDetail.apiVersion === "1.0.0" && orderDetail.discountTotal > 0) &&
                      <Typography className={classes.font16} gutterBottom align="right"><span style={{ color: titleColor }}>HK$ -{orderDetail.discountTotal.toFixed(1)}</span></Typography>
                    }
                    {
                      orderDetail.batches?.map((batch) => (
                        batch?.items?.map((item) => (
                          (orderDetail.apiVersion === "2.0.0" && _.get(item, 'modifiers.length') > 0 && !item.cancelled) && (
                            item?.modifiers?.map((modifier) => (
                              modifier?.map((m, index) => {
                                return (
                                  m.type !== "MERCHANT" && (
                                    <Typography className={classes.font16} gutterBottom align="right"><span style={{ color: titleColor }} key={index}>
                                      HK$ {m.calculatedAmount}
                                    </span></Typography>
                                  )
                                )
                              })
                            ))
                          )
                        ))
                      ))
                    }
                    {
                      orderDetail.deliveryType === "storeDelivery" &&
                      <Typography className={classes.font16} gutterBottom align="right">
                        {
                          shippingDiscount > 0 ? (
                            <React.Fragment>
                              <s style={{ color: titleColor, marginRight: '4px' }}>HK$ {originalShippingFee}</s>
                              <span style={{ color: numColor }}>HK$ {shippingFee}</span>
                            </React.Fragment>
                          ) : <span style={{ color: titleColor }}>HK$ {shippingFee}</span>
                        }
                      </Typography>
                    }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} className={classes.noPaddingBottomNTop}>
                    <hr className="border" />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.lastCell}>
                    <Typography className={classes.font16} gutterBottom><span style={{ color: titleColor }}><b>{t('total')}</b></span></Typography>
                  </TableCell>
                  <TableCell className={classes.lastCell}>
                    {
                      orderDetail.status === "cancelled"
                        ? <Typography className={classes.font16} gutterBottom align="right"><span style={{ color: numColor }}><b>HK$ 0</b></span></Typography>
                        : (
                          orderDetail.apiVersion === '1.0.0' ?
                            <Typography className={classes.font16} gutterBottom align="right"><span style={{ color: numColor }}><b>HK$ {orderDetail.roundedTotal + shippingFee}</b></span></Typography>
                            :
                            <Typography className={classes.font16} gutterBottom align="right"><span style={{ color: numColor }}><b>HK$ {orderDetail.roundedTotal}</b></span></Typography>
                        )
                    }
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
        </div>
      </div>
    );
  }
  else { return (<div></div>); }
}

export default App;
